import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Plan } from "backend/utils/plan";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import Radio from "components/Radio";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { getRelativePathname } from "lib/getRelativePathname";
import { useUserData } from "hooks/useUser";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import PlanService from "services/PlanService";
import { createHelpRequest } from "services/ZendeskService";
import posthog from "posthog-js";

export const CancelSubscriptionFeedback = () => {
  const user = useUserData();
  const theme = useTheme();
  const [selectedPoint, setSelectedPoint] = useState("Technical issues");
  const { plans } = useContext(PlanModalContext);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [comment, setComment] = useState<string>("");

  const feedbackPoints = [
    "Technical issues",
    "Too Expensive",
    "Got what I need / no longer need the service",
    "Switching to another product",
    "Not sure how to use the data & tools",
    "Shutting down the company",
    "Missing features I need",
    "Other ( please explain below)",
  ];

  const handleSendFeedback = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const freePlanId = plans.find((plan) => plan.name === Plan.Free)?.id;
      await PlanService.changePlan(user.id!, freePlanId || "");
      router.replace(
        `${getRelativePathname(
          router,
        )}?showDowngradeModal=true&planCanceled=true`,
      );
      createHelpRequest({
        comment: `${selectedPoint}. ${comment}`,
        user: { email: user?.email, name: user?.name },
        subject: "Subscription Downgrade",
      });
    } catch (error: any) {
      toast(error.message, {
        type: "error",
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    router.replace(getRelativePathname(router));
  };

  return (
    <Modal
      isOpen={!!router.query.showCancelSubscriptionFeedback}
      overlayStyle={{ overflow: "auto", padding: "34px 12px" }}
      contentStyle={{
        maxWidth: 360,
        background: "white",
        width: "100%",
        padding: "15px 24px",
        border: `1px solid ${theme.colors.radioBorder}`,
        overflow: "unset",
      }}
    >
      <Flex
        justify="center"
        align="center"
        css={{
          borderRadius: theme.radii.smaller,
          background: theme.colors.lightBg,
          height: 30,
          position: "absolute",
          width: 30,
          right: 15,
          top: 15,
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
      </Flex>
      <div
        css={{
          marginTop: -45,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            border: "10px solid white",
            borderRadius: theme.radii.full,
          }}
        >
          <Image
            alt="headshot"
            src="/nick-headshot.png"
            css={{ borderRadius: theme.radii.full }}
            width={96}
            height={96}
          />
        </div>
      </div>
      <div css={{ textAlign: "center" }}>
        <h1
          css={{
            fontSize: theme.fontSizes.larger,
            fontWeight: theme.fontWeights.heading,
            margin: 0,
            overflowWrap: "break-word",
          }}
        >
          Hey {user?.name!?.split(" ")[0].split("@")[0]}, before you go...
        </h1>
        <div css={{ opacity: 0.5 }}>
          We&apos;re sorry you&apos;re thinking of leaving us. Would you like to
          tell us why?
        </div>
      </div>
      <Flex gap={10} css={{ marginTop: 12 }} direction="column">
        {feedbackPoints.map((item) => (
          <div
            css={{
              width: "100%",
              border: `1px solid ${theme.colors.radioBorder}`,
              padding: "8px 10px",
              borderRadius: theme.radii.smaller,
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
            key={item}
            onClick={() => {
              setSelectedPoint(item);
            }}
          >
            <Radio
              variant="primary"
              hideBorder
              checked={selectedPoint === item}
            />
            <div>{item}</div>
          </div>
        ))}
        <textarea
          css={{
            width: "100%",
            border: `1px solid ${theme.colors.inputBorder}`,
            padding: "10px 16px",
            borderRadius: theme.radii.smaller,
            ":focus": {
              outline: "none",
            },
            resize: "unset",
          }}
          placeholder="Anything you want to share ( optional)"
          rows={4}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button
          onClick={handleSendFeedback}
          css={{ width: "100%" }}
          variant="alternate"
        >
          {loading ? "Loading..." : "Send feedback"}
        </Button>
      </Flex>
    </Modal>
  );
};
